import React, { useEffect, useState } from "react";
import "./landing.css";
import FieldServiceIco from "../assets/FieldService.png";
import ReturnaProductIco from "../assets/ReturnProduct.png";
import { LabelText, PaStepper } from "@emerson/dynamic-ui-public";
import User from "./user/User";
import SelectFieldService from "./selectFieldService/selectFieldService";
import SysInfo from "./sysInfo/SysInfo";
import SiteInfo from "./siteInfo/SiteInfo";
import SubmitRequest from "./submitRequest/SubmitRequest";
import MaterialSystemDoc from "./materialSystemDoc/MaterialSystemDoc";
import axios from "axios";
import * as locationList from "./location.js";
import EmailData from "./wccEmail.js";

const Landing = () => {
  const [serviceTypeName, setServiceTypeName] = useState("");
  const [activeInStep, setActiveInStep] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [userSiteList, setUserSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [userDeviceList, setUserDeviceList] = useState([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState([]);
  const [oracleAccId, setOracleAccId] = useState(undefined);
  const [logInStatus, setLogInStatus] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionTrigger, setSessionTrigger] = useState(false);
  const [convertedMSDSDoc, setConvertedMSDSDoc] = useState(null);
  const [siteConditions, setSiteConditions] = useState([]);
  const [siterequirements, setSiterequirements] = useState([]);
  const [addSiteInfoDetails, setAddSiteInfoDetails] = useState("");
  const [prefSerDate, setPrefSerDate] = useState("");
  const [prefDevDate, setPrefDevDate] = useState("");
  const [addDetails, setAddDetails] = useState("");
  const [sysInfoDetails, setSysInfoDetails] = useState("");
  const [productsMeasured, setProductsMeasured] = useState("");
  const [wccEmail, setWccEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [addressLine, setAddressLine] = useState({});
  const [endUser, setEndUser] = useState("");
  const [endUserSiteAddress, setEndUserSiteAddress] = useState("");
  const [endUserCountry, setEndUserCountry] = useState("");
  const [endUserStateProvince, setEndUserStateProvince] = useState("");
  const [endUserCity, setEndUserCity] = useState("");
  const [endUserPostalZipCode, setEndUserPostalZipCode] = useState("");
  const [stepMemoize, setStepMemoize] = useState("");
  const [reason, setReason] = useState("");
  const [system, setSystem] = useState({
    sysProject: "",
    sysModel: "",
    sysSerialNumber: "",
  });
  const [deviceData, setDeviceData] = useState([]);
  const [stepperDisable, setStepperDisable] = useState(false);
  const [additionalReturnDetail, setAdditionalReturnDetail] = useState(false);

  // const REACT_APP_API_URL_NODE="http://localhost:5000/api/";
  const REACT_APP_API_URL_NODE = process.env.REACT_APP_API_URL_NODE;
  const REACT_APP_BASEURL_SELF = process.env.REACT_APP_BASEURL_SELF;
  const loca = locationList?.locationList?.map((loc, idx) => {
    return loc?.RecordName;
  });
  const CsvReceivedData = [];
  const quoteFormDetails = {
    service_request_info: serviceName,
    selected_site_Info: selectedSite?.customer_name,
    preferred_service_date: prefSerDate,
    additional_details: addDetails,
    site_info_add_details: addSiteInfoDetails,
    system_type_info: sysInfoDetails,
    site_conitions: siteConditions,
    site_requirements: siterequirements,
    devices: selectedDeviceList,
    end_user: endUser,
    end_user_site_address: endUserSiteAddress,
    email: addressLine?.email1 || "",
  };
  sysInfoDetails === "Engineered Solution" &&
    ((quoteFormDetails.system_model = system.sysModel),
    (quoteFormDetails.serial_number_system = system.sysSerialNumber),
    (quoteFormDetails.project_number = system.sysProject));
  const srFormDetails = {
    service_request_info: serviceName,
    selected_site_Info: selectedSite?.customer_name,
    process_type: productsMeasured,
    devices: selectedDeviceList,
    reason_for_return: reason,
    preferred_service_date: prefDevDate,
    additional_details: addDetails? addDetails: "",
    end_user: endUser? endUser: "",
    end_user_site_address: endUserSiteAddress? `${endUserSiteAddress},${endUserCountry},${endUserStateProvince},${endUserCity},${endUserPostalZipCode}`: "",
    email: addressLine?.email1 || ""
  };

  function getEmailByCountry(country, type) {
    const worldArea =
      selectedSite === "" ? selectedSite.world_area : "North America";
    if (worldArea && EmailData[worldArea]) {
      const countryData = EmailData[worldArea][country];
      if (countryData && countryData[type]) {
        return countryData[type];
      }
    }
    const defaultEmail =
      EmailData["North America"] && EmailData["North America"][type];
    if (defaultEmail) {
      return defaultEmail;
    } else {
      throw new Error(
        `Default email type ${type} not found for North America.`
      );
    }
  }
  const sendEmailApi = (subject, toMail, requesType) => {
    let data = {
      fromMailAddress: "measurement-instrumentation.services-hub@emerson.com",
      toMailAddress: toMail !== "" ? toMail : wccEmail,
      ccMailAddress:
        toMail === ""
          ? "measurement-instrumentation.services-hub@emerson.com"
          : "",
      bccMailAddress: "",
      subject: `${subject} - New MSOL ${
        requesType === "CreateQuote" ? "Service" : "RMA"
      } Request`,
      contentType: "text/html",
      mailbody: `A new  ${
        requesType === "CreateQuote" ? "service request" : "RMA"
      } has been requested using the MSOL Global service request form. Your reference number is "${subject}". ${
        toMail === ""
          ? "Please upload the attachments in WCC."
          : "Someone will reach out to you in 1-2 business days."
      }`,
      attachment:
        toMail === ""
          ? requesType === "CreateQuote"
            ? [
                {
                  filename: `${subject}_Form.csv`,
                  filecontent: CsvReceivedData[0],
                  contentType: "text/csv",
                },
              ]
            : convertedMSDSDoc?
            [
                {
                  filename: `${subject}_Form.csv`,
                  filecontent: CsvReceivedData[0],
                  contentType: "text/csv",
                },
                {
                  filename: `${subject}_MSDS_form.${convertedMSDSDoc?.format}`,
                  filecontent: `${convertedMSDSDoc?.base64}`,
                  contentType: `${convertedMSDSDoc?.mimeType}`,
                },
              ]:
              [
                {
                  filename: `${subject}_Form.csv`,
                  filecontent: CsvReceivedData[0],
                  contentType: "text/csv",
                },
              ]
          : [],
    };

    let emailConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${REACT_APP_API_URL_NODE}api/v1/sendEmail`,
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      data: data,
    };

    axios
      .request(emailConfig)
      .then((response) => {
        response && setActiveInStep(4);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCsvfromApi = (inputData) => {
    let data = JSON.stringify(inputData);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${REACT_APP_API_URL_NODE}api/v1/jsonToCsv`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        CsvReceivedData.push(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // self api call
  const selfCallApi = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${REACT_APP_BASEURL_SELF}//wcs/resources/store/20151/person/@self`, // this needs to hard coded else it won't work
      withCredentials: true,
    };
    axios
      .request(config)
      .then((response) => {
        setOracleAccId(
          response?.data?.distinguishedName?.split("-")[1]?.split(",")[0]
        );
        setAddressLine(response?.data);
        setLogInStatus(response?.data?.email1);
      })
      .catch((error) => {
        console.log(error);
        setLogInStatus(undefined);
      });
  };
  // fetch data
  const fetchUserSitesDevices = (ipOracleId, reqType) => {
    const config = {
      method: "post",
      url: `${REACT_APP_API_URL_NODE}api/v1/${reqType}`,
      headers: { "Content-Type": "application/json" },
      data: ipOracleId,
    };
    axios(config)
      .then((response) => {
        reqType === "GetAllSites" && setUserSiteList(response.data);
        reqType === "GetDevices" && setUserDeviceList(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Quote Data
  const QuoteData = JSON.stringify({
    RecordName: serviceName.replaceAll(" ", "") + "_" + new Date(),
    RFQReceivedDate_c: new Date(),
    PrimaryBusinessCategory_c: "MSOL_LIFECYCLE_SERVICES", //life cycle services {category}   static
    BusinessCategory_c: "MSOL_LIFECYCLE_SERVICES", //life cycle services {category} static
    Location_c: `${loca.filter((locate) => locate.includes("US"))[0]}`,
    Tier_c: "0", // static
    KindOfBusiness_c: "3.4", //static
    Email: addressLine?.email1,
    TeamQueue: `${
      locationList?.locationList
        ?.map((loc, idx) => {
          return loc;
        })
        .filter((locate) => locate.RecordName.includes("US"))[0]?.QuoteTeamQueue
    }`,
  });

  // Support Request Data
  const SRData = JSON.stringify({
    Title: serviceName.replaceAll(" ", "") + "-" + new Date(),
    CustomerRequestType_c: "DEPOT_SERVICE_IN_HOUSE",
    IsThisAPossibleQualityIssue_c: "NO_ISSUE",
    WorkCode_c: "CUSTOMER_PO",
    ProdGroupName: "Lifecycle Services {Category}",
    Location_c: `${loca.filter((locate) => locate.includes("US"))[0]}`,
    Email: addressLine?.email1,
    TeamQueue: `${
      locationList?.locationList
        ?.map((loc, idx) => {
          return loc;
        })
        .filter((locate) => locate.RecordName.includes("US"))[0]?.SRTeamQueue
    }`,
  });

  // quote or support resquest api call
  const createSRorQuote = (dataIp, creationType) => {
    creationType === "SupportRequest" && getCsvfromApi(srFormDetails);
    creationType === "CreateQuote" && getCsvfromApi(quoteFormDetails);
    var config = {
      method: "post",
      url: `${REACT_APP_API_URL_NODE}api/v1/${creationType}`, //CreateQuote  //SupportRequest
      headers: {
        "Content-Type": "application/json",
      },
      data: dataIp,
    };

    axios(config)
      .then(function (response) {
        setErrorMsg("");
        sendEmailApi(
          creationType === "SupportRequest"
            ? response.data.data[0].srNumber
            : response.data.data[0].id,
          addressLine?.email1,
          creationType
        );
        sendEmailApi(
          creationType === "SupportRequest"
            ? response.data.data[0].srNumber
            : response.data.data[0].id,
          "",
          creationType
        );
        setIsLoading(false);
      })
      .catch(function (error) {
        setErrorMsg(
          "Request not submitted. please check the data you have provided is correct or you don't have access to submit the request."
        );
        setIsLoading(false);
        console.log(error);
        setTimeout(() => {
          setErrorMsg("");
        }, 5000);
      });
  };
  useEffect(() => {
    setWccEmail(
      getEmailByCountry(
        selectedSite?.country,
        serviceTypeName === "Request Field Service" ? "Quote" : "SupportRequest"
      )
    );
  }, [selectedSite]);

  // useEffects
  useEffect(() => {
    selfCallApi();
    const interval = setInterval(() => {
      selfCallApi();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    fetchUserSitesDevices({ oracleAccId: oracleAccId }, "GetAllSites");
  }, [oracleAccId]);

  useEffect(() => {
    fetchUserSitesDevices(
      {
        gdm_customer_number: selectedSite.gdm_customer_number, //selectedSite.gdm_customer_number
      },
      "GetDevices"
    );
  }, [selectedSite]);

  useEffect(() => {
    logInStatus !== undefined &&
      (serviceTypeName !== "" || serviceTypeName !== null) &&
      setActiveInStep(1);
  }, [logInStatus]);

  useEffect(() => {}, [activeInStep, quoteFormDetails, srFormDetails]);

  useEffect(() => {
    setServiceTypeName(sessionStorage.getItem("serviceTypeName"));
  }, [sessionTrigger]);

  // jsx start
  return (
    <div className="LandingPage">
      {serviceTypeName === "" || serviceTypeName === null ? (
        <div className="ServiceRequestBox">
          <LabelText
            text="What Are Your Service Needs?"
            textClass="ddl-text--h2"
          />
          <div className="serviceType">
            <div
              onClick={() => {
                sessionStorage.setItem(
                  "serviceTypeName",
                  "Request Field Service"
                );
                setSessionTrigger(!sessionTrigger);
              }}
            >
              <img src={FieldServiceIco} alt="Icon" />
              Field Service
            </div>
            <div
              onClick={() => {
                sessionStorage.setItem("serviceTypeName", "Return A Product");
                setSessionTrigger(!sessionTrigger);
              }}
            >
              <img src={ReturnaProductIco} alt="Icon" />
              Return a Product
            </div>
          </div>
        </div>
      ) : (
        <div className="mainContent">
          <LabelText text={serviceTypeName} textClass="ddl-text--h2" />
          <PaStepper
            activeIndex={activeInStep}
            data={[
              {
                step: 1,
              },
              {
                step: 2,
              },
              {
                step: 3,
              },
              {
                step: 4,
              },
              {
                step: 5,
              },
            ]}
            disableNextStep={stepperDisable}
            lastStepName="Finish"
            onChange={(id) => {
              setActiveInStep(id.step - 1);
            }}
          />
          <div className="contentBoxMain">
            {activeInStep === 0 && (
              <User
                activeInStep={activeInStep}
                setActiveInStep={setActiveInStep}
                oracleAccId={oracleAccId}
                logInStatus={logInStatus}
                setStepperDisable={setStepperDisable}
                stepMemoize={stepMemoize}
                setServiceName={setServiceName}
              />
            )}
            {activeInStep === 1 && (
              <SelectFieldService
                userSiteList={userSiteList}
                serviceTypeName={serviceTypeName}
                activeInStep={activeInStep}
                setActiveInStep={setActiveInStep}
                serviceName={serviceName}
                setServiceName={setServiceName}
                setSelectedSite={setSelectedSite}
                selectedSite={selectedSite}
                srFormDetails={srFormDetails}
                quoteFormDetails={quoteFormDetails}
                setPrefSerDate={setPrefSerDate}
                setSysInfoDetails={setSysInfoDetails}
                setAddDetails={setAddDetails}
                setEndUserSiteAddress={setEndUserSiteAddress}
                endUserSiteAddress={endUserSiteAddress}
                setEndUser={setEndUser}
                endUser={endUser}
                setStepperDisable={setStepperDisable}
                setStepMemoize={setStepMemoize}
                stepMemoize={stepMemoize}
              />
            )}
            {activeInStep === 2 && (
              <SysInfo
                activeInStep={activeInStep}
                setActiveInStep={setActiveInStep}
                serviceTypeName={serviceTypeName}
                serviceName={serviceName}
                setServiceName={setServiceName}
                userDeviceList={userDeviceList}
                setSelectedDeviceList={setSelectedDeviceList}
                userSiteList={userSiteList}
                selectedDeviceList={selectedDeviceList}
                setSelectedSite={setSelectedSite}
                selectedSite={selectedSite}
                setSysInfoDetails={setSysInfoDetails}
                sysInfoDetails={sysInfoDetails}
                setSystem={setSystem}
                setStepperDisable={setStepperDisable}
                setStepMemoize={setStepMemoize}
                stepMemoize={stepMemoize}
                setDeviceData={setDeviceData}
                deviceData={deviceData}
                setReason={setReason}
                reason={reason}
                setAdditionalReturnDetail={setAdditionalReturnDetail}
                additionalReturnDetail={additionalReturnDetail}
                setPrefSerDate={setPrefSerDate}
                prefDevDate={prefDevDate} 
                setPrefDevDate={setPrefDevDate}
                setEndUserSiteAddress={setEndUserSiteAddress}
                endUserSiteAddress={endUserSiteAddress}
                setEndUser={setEndUser}
                endUser={endUser}
                setAddDetails={setAddDetails}
                endUserCountry={endUserCountry}
                setEndUserCountry={setEndUserCountry}
                endUserStateProvince={endUserStateProvince}
                setEndUserStateProvince={setEndUserStateProvince}
                endUserCity={endUserCity}
                setEndUserCity={setEndUserCity}
                endUserPostalZipCode={endUserPostalZipCode}
                setEndUserPostalZipCode={setEndUserPostalZipCode}
              />
            )}
            {activeInStep === 3 &&
              serviceTypeName === "Request Field Service" && (
                <SiteInfo
                  activeInStep={activeInStep}
                  setActiveInStep={setActiveInStep}
                  createSRorQuote={createSRorQuote}
                  SRData={QuoteData}
                  srFormDetails={srFormDetails}
                  quoteFormDetails={quoteFormDetails}
                  setSiterequirements={setSiterequirements}
                  setSiteConditions={setSiteConditions}
                  setAddSiteInfoDetails={setAddSiteInfoDetails}
                  siteConditions={siteConditions}
                  siterequirements={siterequirements}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  errorMsg={errorMsg}
                  setStepperDisable={setStepperDisable}
                  setStepMemoize={setStepMemoize}
                  stepMemoize={stepMemoize}
                />
              )}
            {activeInStep === 3 && serviceTypeName === "Return A Product" && (
              <MaterialSystemDoc
                activeInStep={activeInStep}
                setActiveInStep={setActiveInStep}
                createSRorQuote={createSRorQuote}
                QuoteData={SRData}
                setConvertedMSDSDoc={setConvertedMSDSDoc}
                srFormDetails={srFormDetails}
                quoteFormDetails={quoteFormDetails}
                setProductsMeasured={setProductsMeasured}
                productsMeasured={productsMeasured}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                convertedMSDSDoc={convertedMSDSDoc}
                errorMsg={errorMsg}
                setStepperDisable={setStepperDisable}
                setStepMemoize={setStepMemoize}
                stepMemoize={stepMemoize}
              />
            )}
            {activeInStep === 4 && (
              <SubmitRequest
                activeInStep={activeInStep}
                setActiveInStep={setActiveInStep}
                serviceTypeName={serviceTypeName}
                setStepperDisable={setStepperDisable}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
